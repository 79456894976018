<template>
    <el-form ref="form" label-width="0">
        <el-row>
            <el-col>
                <el-upload ref="upload" class="upload-demo" accept='.jsonl' action="#" :on-change="handleFile"
                    :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" multiple
                    :on-exceed="handleExceed" :file-list="fileList" :auto-upload="false">
                    <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
            </el-col>
        </el-row>
        <el-table :data="tableList" style="width: 100%;margin-top: 10px;" :header-cell-style="headerCellStyle">
            <el-table-column prop="trainingfileid" label="chatgptffid" align="left">
            </el-table-column>
            <el-table-column prop="finetunedmodel" label="finetunedmodel" align="left">
            </el-table-column>
            <el-table-column prop="chatgptffid" label="chatgptffid" align="left">
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="text" @click="delMod(scope.row)" v-show="scope.row.finetunedmodel != null">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-form>
</template>
<script>
import { importfile, fileList, delMould,getHistory } from '@/api/index'
export default {
    data() {
        return {
            form: {},
            tableData: [],
            fileList: [],
            tableList: [],
            headerCellStyle: {
                background: "antiquewhite",
                color: "brown"
            },
        }
    },
    created() {
        this.getList()
    },
    methods: {
        handleFile(file, fileList) {
            let formdata = new FormData()
            formdata.append('file', file.raw)
            importfile(formdata).then(res => {
                if (res.code == 200) {
                    this.$message.success('上传成功')
                    this.getList()
                    this.$refs.upload.clearFiles()
                }
            })
        },
        // 删除model
        delMod(e) {
            console.log(e);
            if (e.finetunedmodel != null) {
                let data = {
                    finetunedmodel: e.finetunedmodel,
                    chatgptffid:e.chatgptffid
                }
                delMould(data).then(res => {
                    if (res.code == 200) {
                        this.$message.success('删除成功')
                        this.getList()
                    }
                })
            } else {
                this.$message.error('这个文件不能删')

            }

        },



        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        getList() {
            // fileList().then(res => {
            //     console.log(res);
            //     if (res.code == 200) {
            //         this.tableList = res.data
            //     }
            // })

            getHistory().then(res => {
                if (res.code == 200) {
                    this.tableList = res.data
                }
            })
        }
    }
}
</script>