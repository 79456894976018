<template>
    <div>
        <el-row :gutter="12">
            <el-col :span="16">
                <el-card>
                    <el-form ref="form" :model="form" label-width="90">
                        <el-row>
                            <el-col :span="24">
                                <el-form-item label="选择类型">
                                    <el-select v-model="selectName" placeholder="请选择" @change="getType(selectName)">
                                        <el-option v-for="item in options" :key="item.id" :label="item.label"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="生成数量">
                                    <el-select v-model="form.n" placeholder="请选择">
                                        <el-option v-for="item in 10" :key="item" :label="item" :value="item">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="图片尺寸">
                                    <el-select v-model="form.size" placeholder="请选择">
                                        <el-option v-for="item in sizes" :key="item.label" :label="item.label"
                                            :value="item.label">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <div v-if="change == 1 || change == 3">
                                    <el-form-item>
                                        <el-input type="textarea" :rows="8" placeholder="请输入内容" v-model="form.prompt">
                                        </el-input>
                                    </el-form-item>
                                    <el-button v-if="change == 1" type="primary" size="mini"
                                        @click="getData(1)">生成图片</el-button>
                                </div>

                                <el-form-item v-show="change == 2">
                                    <ImgCutter @cutDown="cutDown2"></ImgCutter>
                                </el-form-item>
                                <el-form-item v-show="change == 3">
                                    先选素材<ImgCutter @cutDown="cutDown3"></ImgCutter>
                                    再选遮罩<ImgCutter @cutDown="cutDown4"></ImgCutter>
                                </el-form-item>

                            </el-col>
                        </el-row>
                        <el-dialog :visible.sync="dialogVisible">
                            <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog>
                    </el-form>
                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card>
                    <el-table :data="tableData" style="width: 100%" :header-cell-style="{ 'text-align': 'center' }">
                        <el-table-column prop="name" label="历史生成图片" align="left">
                        </el-table-column>
                    </el-table>
                </el-card>
            </el-col>
        </el-row>
        <el-row :gutter="12" style="margin-top: 10px;">
            <el-col :span="16">
                <el-card>
                    <el-image style="width: 200px; height: 200px;margin-right: 5px;" v-for="item, index in resData"
                        :key="index" :src="item.url" :preview-src-list="preBigImg"></el-image>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { gptImgType1, uploadFile, uploadFile2 } from '@/api/index'
import ImgCutter from 'vue-img-cutter'
export default {
    components: {
        ImgCutter
    },
    data() {
        return {
            form: {
                n: '1',
                size: '256x256'
            },
            tableData: [
                { name: '描述列表1' },
                { name: '描述列表1' },
                { name: '描述列表1' },
                { name: '描述列表1' },
            ],
            selectName: '',
            change: 1,
            dialogImageUrl: '',
            dialogVisible: false,
            cutVisible: false,
            url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
            options: [
                { label: '生成图片', id: 1 },
                { label: '生成图片variation', id: 2 },
                { label: '生成编辑图片edited', id: 3 },
            ],
            sizes: [
                { label: '256x256' },
                { label: '512x512' },
                { label: '1024x1024' },
            ],
            timeout: null,
            resData: [],
            preBigImg: [],
            isUpload: false,
            fileData: [],
            curUrl: {},
            formData: {},
        }
    },
    created() {
        this.selectName = this.options[0].label
    },
    methods: {
        cutDown2(e) {
            // localStorage.setItem('formData', true)
            this.formData = new FormData();
            this.formData.append('n', this.form.n)
            this.formData.append('size', this.form.size)
            this.formData.append('image', e.file)
            uploadFile(this.formData).then(res => {
                if (res.code == 200) {
                    // localStorage.removeItem('formData');
                    this.resData = res.data
                    this.resData.map(item => {
                        this.preBigImg.push(item.url)
                    })
                }
            })
        },

        cutDown3(e) {
            this.formData = new FormData();
            this.formData.append('n', this.form.n)
            this.formData.append('size', this.form.size)
            this.formData.append('prompt', this.form.prompt)
            this.formData.append('image', e.file)
        },
        cutDown4(e) {
            // localStorage.setItem('formData', true)
            this.formData.append('mask', e.file)
            uploadFile2(this.formData).then(res => {
                if (res.code == 200) {
                    // localStorage.removeItem('formData');
                    this.resData = res.data
                    this.resData.map(item => {
                        this.preBigImg.push(item.url)
                    })
                    
                }
            })
        },
        submit() {
            gptImgType1(this.form).then(res => {
                console.log('res',res);
                if (res.code == 200) {
                    this.resData = res.data
                    this.resData.map(item => {
                        this.preBigImg.push(item.url)
                    })
                }

            })
        },
        getType(e) {
            this.change = e
        },
        getData(e) {
            if (e == 1) {
                this.submit()
            }
        },
        reset() {
            this.formData = {}
            this.isUpload = false
            // this.$refs.upload.clearFiles()
            this.fileData = []
            this.resData = []
            this.form = {
                n: '1',
                size: '256x256'
            }
        },


        /** 
        // 文件上传部分
            handleFile(file, fileList) {
                this.curUrl = file
                this.cutVisible = true
                console.log('file', file);
                if (this.change == 2) {
                    this.isUpload = true
                }
                if (this.change == 3) {
                    if (fileList.length == 2) {
                        this.isUpload = true
                    }
                }
                this.fileData = fileList
            },
            handleRemove(file, fileList) {
                console.log(file, fileList);
                if (this.change == 2) {
                    setTimeout(() => {
                        this.isUpload = false
                    }, 1000)
                }
                if (this.change == 3) {
                    if (fileList.length == 2) {
                        setTimeout(() => {
                            this.isUpload = false
                        }, 1000)
                    }
                }
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            close() {
                this.cutVisible = false
            },
        */


    },
    watch: {
        change() {
            this.reset()
        }
    }
}
</script>
<style lang="less" scoped>
// 去掉表格所有的横线
/deep/ .el-table__row>td {
    border: none;
}

/deep/ .el-table::before {
    height: 0px;
}

// 隐藏上传图片按钮
// .hide_box /deep/ .el-upload--picture-card {
//     display: none;
// }
</style>