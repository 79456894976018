import http from '../utils/request'
export const getMenu = (data) => {
    return http.post('/api/permission/getMenu', data)
}
// 图片生成
export const gptImgType1 = (data) => {
    return http.post('/chat/v1/images/generations',data)
}

export const uploadFile = (data) => {
    return http.post('/chat/v1/images/variations',data)
}
export const uploadFile2 = (data) => {
    return http.post('/chat/v1/images/edit',data)
}
// 模型训练
export const mouldslist = (data) => {
    return http.get('/chat/v1/listmodels',data)
}
export const AiReplay = (data) => {
    return http.post('/chat/v1/chat/features',data)
}
// 导入文件
export const importfile = (data) => {
    return http.postForm('/chat/v1/fintuner/uploadfile',data)
}
// 文件列表
export const fileList = (data) => {
    return http.postForm('/chat/v1/fintuner/filelist',data)
}
// 立即训练
export const asSoonAsTrain = (data) => {
    return http.postw('/chat/v1/fintuner/createfintu',data)
}
// 查看历史
export const getHistory = (data) => {
    return http.get('/chat/v1/fintuner/allmodxun', data)
}
// 查看微任为进度
export const seeProgress = (data) => {
    return http.get('/chat/v1/fintuner/detailfintu?finetuneid='+data)
}
// 删除模型
export const delMould = (data) => {
    return http.get('/chat/v1/fintuner/deletemodelname?modelname='+ data.finetunedmodel + '&chatgptffid=' +data.chatgptffid)
}




// // 请求首页数据
// export const getData = () => {
//     // 返回一个promise对象
//     return http.get('/home/getData')
// }

// export const getUser = (params) => {
//     console.log(params, 'params')
//     // 返回用户列表
//     return http.get('/user/getUser', params)
// }

// export const addUser = (data) => {
//     return http.post('/user/add', data)
// }

// export const editUser = (data) => {
//     return http.post('/user/edit', data)
// }

// export const delUser = (data) => {
//     return http.post('/user/del', data)
// }

