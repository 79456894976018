import axios from 'axios'
import qs from 'qs'
// let baseUrl = 'http://52.201.220.157:15563/ai'
let baseUrl = 'https://gpt.xdn10000.com/prod-api/ai' 
// let baseUrl = '/api' //登录的
// let baseUrl = 'http://192.168.5.105:15563/prod-api/ai'
const http = axios.create({
  // 通用请求的地址前缀
  baseURL: baseUrl,
  timeout: 120000, // 超时时间
})

// 添加请求拦截器
http.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  // isLocal ? config.headers['Content-Type'] = 'Content-Type: multipart/form-data' : null
  // isForm ? config.headers['Content-Type'] = 'Content-Type: application/x-www-from-urlencoded' : null

  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
http.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});

export default {
  post(url, data) {
    return http({
      method: 'post',
      url,
      data: data,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json; charset=UTF-8'

      }
    }).then(response => {
      return response.data
    })
  },
  postw(url, data) {
    return http({
      method: 'post',
      url,
      data:qs.stringify(data),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'

      }
    }).then(response => {
      return response.data
    })
  },
  postForm(url, data) {
    return http({
      method: "post",
      url,
      data: data,
      headers: {
        'Content-type': 'multipart/form-data'
      },
    }).then(response => {
      return response.data
    })


  },
  get(url, params) {
    return http({
      method: 'get',
      url,
      params, // get 请求时带的参数
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    }).then(response => {
      return response.data
    })
  },
  getw(url, params) {
    return http({
      method: 'get',
      url,
      params:qs.stringify(params), // get 请求时带的参数
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'
      }
    }).then(response => {
      return response.data
    })
  },
}