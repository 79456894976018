<template>
    <el-row>
        <el-col :span="24">
            <!-- <el-card> -->
            <el-form ref="form" :model="form" label-width="90px">
                <el-row>
                    <el-col :span="24">
                        <div style="display:flex;justify-content: center;align-items: center;">
                            <el-form-item label="选择model">
                            <el-select v-model="form.model" placeholder="请选择">
                                <el-option v-for="item in modelList" :key="item.id" :label="item.id" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        </div>
                        
                    </el-col>

                </el-row>
                <el-row>
                    <el-table :data="speakRecord" style="width: 100%;font-size: 18px;padding-bottom: 100px;" stripe
                        height="450">
                        <el-table-column prop="Aihistory" label="本轮对话记录" align="center">
                            <template slot-scope="scope">
                                <div class="box" v-html="scope.row.Aihistory"></div>
                            </template>
                        </el-table-column>
                    </el-table>

                </el-row>

                <!-- 发送 -->
                <div class="ask">
                    <!-- <el-input type="textarea" placeholder="请输入内容" v-model.trim="user.content"
                        :autosize="{ minRows: 5, maxRows: 5 }" @keyup.enter.native="getUserAsk(user.content)"
                        :disabled="isDisabled" :maxlength="2048" :show-word-limit="true" class="askTextarea" resize="none">

                    </el-input>
                    <div class="askBtn">
                        <el-button type="primary" @click="getUserAsk(user.content)" :disabled="isDisabled">发送</el-button>
                    </div> -->
                    <!-- 富文本编辑器组件 -->
                    <quill-editor ref="myQuillEditor" :options="editorOption"
                        @change="onEditorChange($event)"></quill-editor>
                    <div class="askBtn">
                        <el-button type="primary" @click="getUserAsk(user.content)" :disabled="isDisabled">发送</el-button>
                    </div>
                    <div class="limit">
                        {{ TiLength }}/2048
                    </div>
                </div>
            </el-form>

            <!-- </el-card> -->
        </el-col>
        <!-- <el-col :span="8">
            <el-card>
                <el-table :data="speakRecord" style="width: 100%">
                    <el-table-column prop="Aihistory" label="本轮对话记录" align="center">
                    </el-table-column>
                </el-table>
            </el-card>
        </el-col> -->
    </el-row>
</template>
<script>
import { mouldslist, AiReplay } from '@/api/index'
import { init } from 'events';
// require styles 导入富文本编辑器对应的样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'


export default {
    components: {
        quillEditor
    },
    data() {
        return {
            form: {
                model: 'gpt-3.5-turbo',
                messages: [],
                uuidtime: '',
                ainame: 'b26m',

            },
            user: { role: 'user', content: '' },
            options: [
                { label: '1', value: 1 },
                { label: '2', value: 2 },
                { label: '3', value: 3 },
            ],
            tableData: [],
            modelList: [],
            // timer: null,
            // AI回答
            Aianswer: '',
            // 说话记录
            speakRecord: [],
            isTu: true,
            rePlay: 0,
            isDisabled: false,
            socket: null,
            editorStr: '',
            // 限制数字
            TiLength: 0,
            editorOption: {
                placeholder: '请输入正文',
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                        ['blockquote', 'code-block'], // 引用  代码块
                        [{ header: 1 }, { header: 2 }], // 1、2 级标题
                        [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
                        [{ script: 'sub' }, { script: 'super' }], // 上标/下标
                        [{ indent: '-1' }, { indent: '+1' }], // 缩进
                        [{ direction: 'rtl' }], // 文本方向
                        [{ size: ['12px', false, '16px', '18px', '20px', '30px'] }], // 字体大小
                        [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
                        [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                        [{ font: [false, 'SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial'] }], // 字体种类
                        [{ align: [] }], // 对齐方式
                        ['clean'], // 清除文本格式
                        ['link', 'image', 'video'] // 链接、图片、视频
                    ],
                },
            }
        }
    },

    created() {
        this.form.uuidtime = new Date().getTime();
        this.getMouldList()
        this.initWebSocket()
    },
    mounted() {
        //数据初始化
        this.TiLength = this.$refs.myQuillEditor.quill.getLength() - 1
    },
    methods: {
        onEditorChange(e) {
            e.quill.deleteText(2048, 1);  //富文本字数限制
            let str = e.html.replace(/<[^>]+>/g, '')
            if ( str.length === 0) {
                this.TiLength = 0
            }
            else {
                this.TiLength = e.quill.getLength()-1
                this.user.content = str.substring(0,this.TiLength)
            }

        },
        getMouldList() {
            mouldslist().then(res => {
                if (res.code == 200) {
                    this.modelList = res.data
                }
            })
        },

        getUserAsk(val) {
            if (val == "") {
                return this.$message.success('请输入问题提问~')
            }
            this.isDisabled = true
            // 要传的参数
            this.form.messages.push({
                role: 'user',
                content: val
            })
            // 表格显示的
            this.speakRecord.push({
                Aihistory: val
            })


            AiReplay(this.form).then(res => {
                // if (res.code == 200) {
                //     this.form.messages = []
                //     this.Aianswer = res.msg
                //     this.form.messages.push({
                //         role: 'system',
                //         content: res.msg
                //     })
                //     this.speakRecord.push({
                //         Aihistory: res.msg
                //     })
                // }
            })

            // clearTimeout(this.timer)
            // this.timer = setTimeout(() => {
            //     this.form.messages.push({
            //         role: 'user',
            //         content: val
            //     })
            //     AiReplay(this.form).then(res=>{
            //         console.log(res);
            //     })
            // }, 2000)
        },
        initWebSocket() {
            let _this = this
            if (typeof (WebSocket) == "undefined") {
                console.log("遗憾：您的浏览器不支持WebSocket");
            } else {
                console.log("恭喜：您的浏览器支持WebSocket");
                //实现化WebSocket对象
                //指定要连接的服务器地址与端口建立连接
                //注意ws、wss使用不同的端口。我使用自签名的证书测试，
                //无法使用wss，浏览器打开WebSocket时报错
                //ws对应http、wss对应https。
                this.socket = new WebSocket("wss://gpt.xdn10000.com/prod-api/ai/chat/v1/ws/xdnsocket");

                // this.socket = new WebSocket("ws://192.168.5.105:15563/prod-api/ai/chat/v1/ws/xdnsocket");
                this.socket.onmessage = this.websocketOnMessage
                this.socket.onopen = this.websocketOnOpen
                this.socket.onerror = this.websocketOnError
                this.socket.onclose = this.websocketClose



                //连接打开事件
                // this.socket.onopen = function () {
                //     socket.send(_this.form.uuidtime);
                // };

                //收到消息事件
                // this.socket.onmessage = function (msg) {
                //     console.log(msg.data);
                //     if (msg.data == 'endendxdn') {
                //         _this.form.messages = []
                //         _this.form.messages.push({
                //             role: 'system',
                //             content: _this.speakRecord[_this.rePlay + 1].Aihistory
                //         })
                //         _this.rePlay += 2
                //         _this.isTu = true
                //         _this.isDisabled = false
                //     } else {
                //         if (_this.isTu) {
                //             _this.speakRecord.push({
                //                 Aihistory: ''
                //             })
                //             _this.speakRecord[_this.rePlay + 1].Aihistory += msg.data;
                //             _this.isTu = false
                //         } else {
                //             _this.speakRecord[_this.rePlay + 1].Aihistory += msg.data;
                //         }

                //     }



                // };
                //连接关闭事件
                // this.socket.onclose = function () {
                //     console.log("Socket已关闭");
                // };

                //发生了错误事件
                // this.socket.onerror = function () {
                //     alert("Socket发生了错误");
                // }
                //窗口关闭时，关闭连接
                window.unload = function () {
                    this.socket.close();
                };
            }
        },
        websocketOnOpen() {
            console.log('初始化成功')
            this.socket.send(this.form.uuidtime);
        },
        websocketOnMessage(msg) {
            let _this = this
            console.log(msg.data);
            if (msg.data == 'endendxdn') {
                _this.form.messages = []
                _this.form.messages.push({
                    role: 'system',
                    content: _this.speakRecord[_this.rePlay + 1].Aihistory
                })
                _this.rePlay += 2
                _this.isTu = true
                _this.isDisabled = false
            } else {
                if (_this.isTu) {
                    _this.speakRecord.push({
                        Aihistory: ''
                    })
                    _this.speakRecord[_this.rePlay + 1].Aihistory += msg.data;
                    _this.isTu = false
                } else {
                    _this.speakRecord[_this.rePlay + 1].Aihistory += msg.data;
                }

            }
        },
        websocketOnError() {
            alert("Socket发生了错误");
            console.log('正在重连')
            this.initWebSocket()
        },
        websocketClose() {
            console.log('断开连接')
            console.log('正在重连')
            this.initWebSocket()
        }

    },
    watch: {
        // user:{
        //     handler(newValue, oldValue) {
        //         let str = newValue.content.replace(/<[^>]+>/g, '')
        //         this.TiLength = str.length
        //         if(this.TiLength == 2048){
        //             this.$message.success('不可再输入')
        //         }
        //     },
        //     deep: true
        // }
    }

}
</script>
<style lang="less" scoped>
/deep/ .el-textarea .el-input__count {
    right: 80px;
}

.ask {
    position: fixed;
    bottom: 100px;
    left: 30%;
    width: 50%;
    display: flex;

    .askBtn {
        position: absolute;
        right: 5px;
        bottom: -60px;
    }

    .limit {
        position: absolute;
        right: 80px;
        bottom: -60px;
    }
}

/deep/ .el-input__inner {
    border: 1px solid #409EFF;
    border-radius: 3px;
    padding-left: 10px;

}
</style>