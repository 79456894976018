<template>
    <el-row :gutter="12">
        <el-col :span="16">
            <el-card>
                <el-form ref="form" :model="form" label-width="40px">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="训练">
                                <el-select v-model="form.trainingfile" placeholder="请选择">
                                    <el-option v-for="item in fileList" :key="item.chatgptid"
                                        :label="item.filename + `---${item.chatgptid}`" :value="item.chatgptid">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="类型">
                                <el-select v-model="form.model" placeholder="请选择">
                                    <el-option v-for="item in modelList" :key="item.label" :label="item.label"
                                        :value="item.label">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item>
                                <el-button type="primary" size="mini" @click="train">立即训练</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>


                </el-form>
                <el-progress :percentage="progressData"></el-progress>
                <div style="text-align: center;">训练进度</div>
                <span>模型名字: {{ modelName ? modelName : '' }}</span>
            </el-card>
        </el-col>
        <el-col :span="8">
            <el-card>
                <el-table :data="historyTableList" style="width: 100%" :header-cell-style="{ 'text-align': 'center' }"
                    @row-click="getProgress">
                    <el-table-column prop="chatgptffid" label="历史生成记录" align="left">
                    </el-table-column>
                </el-table>
            </el-card>
        </el-col>
    </el-row>
</template>
<script>

import { fileList, asSoonAsTrain, seeProgress, getHistory } from '@/api/index'
export default {
    data() {
        return {
            dialogVisible: false,
            form: {},
            pageData: {
                page: 1,
                limit: 10
            },
            userForm: {
                name: ''
            },
            fileList: [],
            fileUrl: '',
            modelList: [
                { label: 'ada' },
                { label: 'babbage' },
                { label: 'curie' },
                { label: 'davinci' },
            ],
            historyTableList: [],
            // 模型名称
            modelName: '',
            // 进度进度
            progressData: 0,
            timer: null,
            curData:null
        }
    },
    created() {
        this.form.uuidtime = new Date().getTime();
        this.getList()
        this.getHis()
    },
    methods: {
        format(percentage) {
            return percentage === 100 ? '满' : `${percentage}%`;
        },
        getList() {
            fileList().then(res => {
                console.log(res);
                if (res.code == 200) {
                    this.fileList = res.data
                }
            })
        },
        getHis() {
            getHistory().then(res => {
                if (res.code == 200) {
                    this.historyTableList = res.data
                }
            })
        },
        // 立即训练
        train() {
            asSoonAsTrain(this.form).then(res => {
                if (res.code == 200) {
                    this.getProgress(res.data.id)
                    this.progressData = 1
                } else {
                    this.$message.error(res.data)
                }
            })
        },
        // 获取文件
        getProgress(id) {
            if(id.chatgptffid){
                this.curData = id.chatgptffid
            }else{
                this.curData = id
            }
            seeProgress(this.curData).then(res => {
                if (res.code == 200) {
                    this.modelName = res.data.fine_tuned_model
                    let lend = res.data.events.length - 1
                    let event = res.data.events
                    if (event[lend].message.includes('Created fine-tune:')) {
                        this.progressData = 10
                        this.zidong(this.curData)
                    } else if (event[lend].message.includes('Fine-tune costs')) {
                        this.progressData = 15
                        this.zidong(this.curData)
                    } else if (event[lend].message.includes('Fine-tune enqueued')) {
                        this.progressData = 20
                        this.zidong(this.curData)
                    } else if (event[lend].message.includes('Fine-tune is in the queue')) {
                        this.progressData = 25
                        this.zidong(this.curData)
                    } else if (event[lend].message.includes('Fine-tune started')) {
                        this.progressData = 30
                        this.zidong(this.curData)
                    } else if (event[lend].message.includes('Completed epoch 1/4')) {
                        this.progressData = 35
                        this.zidong(this.curData)
                    } else if (event[lend].message.includes('Completed epoch 2/4')) {
                        this.progressData = 40
                        this.zidong(this.curData)
                    } else if (event[lend].message.includes('Completed epoch 3/4')) {
                        this.progressData = 45
                        this.zidong(this.curData)
                    } else if (event[lend].message.includes('Completed epoch 4/4')) {
                        this.progressData = 50
                        this.zidong(this.curData)
                    } else if (event[lend].message.includes('Uploaded model')) {
                        this.progressData = 60
                        this.zidong(this.curData)
                    } else if (event[lend].message.includes('Uploaded result file:')) {
                        this.progressData = 80
                        this.zidong(this.curData)
                    } else if (event[lend].message.includes('Fine-tune succeeded')) {
                        this.progressData = 100
                        this.$message.success('训练完毕')
                    }

                } else {
                    this.$message.error(res.data)
                }
            })
        },
        zidong(e) {
            setTimeout(() => {
                this.getProgress(e)
            }, 2000)
        }

    },
    mounted() {
    }
}
</script>
<style lang="less" scoped>
// 去掉所有的横线
/deep/ .el-table__row>td {
    border: none;
}

/deep/ .el-table::before {
    height: 0px;
}
</style>